import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/PageHeader';
import { graphql, Link } from 'gatsby';
import Container from '../components/Container';
import Studies from '../components/Studies';
import Footer from '../components/Footer';

export default (props)=>{
  return(
    <Layout>
      <PageHeader>
        <h2 className="text-6xl">Estudios y Pruebas</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Estudios y Pruebas</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/servicios" className="underline">Estudios y Pruebas</Link>
        </p>
      </PageHeader>
      <Container className="mb-12">
        <p className="my-4 mb-6 text-lg font-bold">Realizamos toda una variedad de estudios de rutina, también perfíles, marcadores y pruebas especiales muy específicas que ayuden en el diagnóstico y control de numerosas enfermedades.</p>
        <div className="border-t-2 border-gray-200 pt-8">
          <Studies fullWidth={true} />
        </div>
      </Container>
      <Footer />
    </Layout>
  );
}